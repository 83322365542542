@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  margin-top: 140px;
}

.title {
  margin-top: 40px;
  @include text-40;
}

.description {
  margin-top: 16px;
  opacity: 0.7;
  @include text-40;
  text-align: center;
}

.button {
  margin-top: 30px;
  padding: 20px 24px;
  color: $white;
  @include text-16;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 600;
  background-color: $dark;
  border-radius: 18px;
  border: 1px solid transparent;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:hover {
    background-color: $white;
    color: $dark;
  }
}
